.physician {
  &_wrapper {
    height: 100vh;
    background: #f3f3f3c0;
    overflow: auto;
    .react-bootstrap-table {
      margin: 0.25rem 2rem;
      table {
        border-radius: 5px;
        th {
          color: #333;
          background: #fff;
        }
        td {
          font-weight: 100;
        }
      }
    }
  }
  &_assessment-question-title {
    font-weight: bold;
  }
  &_assessment-wrapper {
    background: #f3f3f3c0;
    height: 100vh;
  }
  &_assessment-content {
    margin: 1rem;
  }
  &_refresh-button {
    border-radius: 5px;
    -webkit-appearance: none;
    background: #fff;
    border: 1px solid lightgray;
    &:active,
    &:focus {
      outline: none;
    }
  }
  &_table-instructions {
    text-align: center;
    margin-top: 1rem;
  }
  &_search-container {
    margin: 0.5rem 2rem 0 2rem;
    text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_data-table-row {
    transition: background 0.2s;
    background: #fff;
    &:hover {
      cursor: pointer;
      background: rgba(250, 249, 249, 0.863);
    }
  }
  &_loader-container {
    text-align: center;
  }
  &_assessment-title {
    margin-bottom: 0;
    font-size: 1.5rem;
    text-align: center;
    font-weight: 100;
  }
  &_assessment-name {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  &_assessment-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 50rem;
    border-radius: 10px;
    margin: 0 auto;
    background: #fff;
    -webkit-box-shadow: 10px 10px 22px -18px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 22px -18px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 22px -18px rgba(0, 0, 0, 0.75);
  }
  &_assessment-results {
    text-align: center;
    padding: 2rem;
  }
  &_assessment-button {
    position: absolute;
    background: #fff;
    -webkit-appearance: none;
    border: 1px solid lightgray;
    transition: background 0.2s;
    border-radius: 5px;
    margin-left: 1rem;
    padding: 0.75rem 1.75rem;
    &:hover {
      cursor: pointer;
      background: #8dc440;
      color: #fff;
    }
  }
}

.physician-header-container {
  padding: 0.25rem 1.25rem;
  width: 100%;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  border-bottom: 1px solid;
  border-image-source: linear-gradient(45deg, #8cc540, #8cc540);
  border-image-slice: 1;
}

.physician-header {
  &_logo-container {
    width: 27rem;
  }
  &_logo {
    width: 100%;
  }
}

.react-bootstrap-table-pagination {
  margin: 0.25rem 2rem;
  .react-bootstrap-table-pagination-list {
    display: flex;
    justify-content: flex-end;
  }
  .page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: lightgray;
    background-color: #fff;
    border: 1px solid #dee2e6;
    &:active,
    &:focus {
      outline: none;
    }
  }
  .page-item.active .page-link  {
    background-color: lightgray;
    border-color: lightgray;
    &:active,
    &:focus {
      outline: none;
    }
  }
  .page-item {
    &:active,
    &:focus {
      outline: none;
    }
  }
}

#pageDropDown {
  background-color: rgba(255, 255, 255, 0);
  color: #333;
}