.homepage-main-content-container {
  flex: 0 0 75%;
}

.homepage-main-content {
  display: flex;
  border: 1px solid rgb(209, 209, 209);
  padding: 1.5rem 0;
  border-radius: 5px;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  &_heading {
    padding-bottom: .75rem;
    border-bottom: 1px solid 	#E8E8E8;
    margin-bottom: 1rem;
  }
  &_heading-text {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    color: #888888;
  }
  &_verbiage-container {
    text-align: center;
  }
  &_verbiage-header {
    font-size: 1.75rem;
    font-weight: bold;
    margin: .75rem 0 15px 0;
    color: #333;
  }
  &_verbiage-sub-header {
    margin: 0 0 28px 0;
    font-weight: 300;
  }
  &_verbiage-link {
    background: #2F5475;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    padding: 10px 40px;
    border-radius: 5px;
    transition: background .25s;
    &:hover {
      background: lighten(#2F5475, 10%);
    }
    &--hdinsight {
      background: #8CC540;
      &:hover {
        background: darken(#8CC540, 8%);
      }
    }
  }
  &_icon {
    font-size: 5rem;
    // color: #F58528;
    // color: #8CC540;
  }
}

.homepage-container {
  height: 100vh;
  background: rgb(235, 235, 235);
  overflow: hidden;
  padding-bottom: 4rem;
}

.homepage-body-container {
  display: flex;
  justify-content: space-around;
  height: 100%;
  overflow: auto;
}

.homepage-sidebar-content {
  flex: 0 0 25%;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  &_text-container {
    margin-left: 1.25rem;
  }
  &_text {
    margin: 0 0 4px 0;
    font-weight: 300;
    &--bold {
      font-weight: bold;
      margin-right: 5px;
    }
  }
  &_heading {
    padding-bottom: .75rem;
    border-bottom: 1px solid 	#E8E8E8;
    margin-bottom: 1rem;
  }
  &_heading-text {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    color: #888888;
  }
  &_icon-container {
    width: 3rem;
  }
  &_icon {
    font-size: 2.5rem;
    &--health {
      color: #63ADF2;
    }
    &--md {
      color: #545E75;
    }
    &--connections{
      color: #82A0BC;
    }
    &--share {
      color: #A7CCED;
    }
    &--discover {
      color: #304D6D;      
    }
  }
}