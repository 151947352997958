.assessment-cover {
  &_container {
    margin: 3rem auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #fff;
    border-top: 5px solid #8cc540;
    -webkit-box-shadow: 10px 10px 22px -18px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 22px -18px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 22px -18px rgba(0,0,0,0.75);
    border-radius: 10px;
    width: 40rem;
    padding: 2rem;
    h2 {
      font-size: 1rem;
    }
  }
  &_submit {
    margin: 0 auto;
    background: none;
    border: 1px solid rgb(194, 194, 194);
    outline: none;
    border-radius: 5px;
    padding: 0.25rem 3rem;
    -webkit-appearance: none;
    transition: background 0.2s, color 0.2s;
    &:hover {
      background: #8cc540;
      color: #fff;
      cursor: pointer;
    }
  }
  &_icon {
    font-size: 5rem;
  }
  &_no-content-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 2rem;
    p {
      margin-bottom: 0;
    }
  }
  &_no-content {
    text-align: left;
  }
  &_no-content-icon {
    font-size: 5rem;
  }
  &_no-content-title {
    font-weight: 500;
  }
  &_no-content-subtitle {
    font-weight: 100;
  }
}

.assessment-instructions {
  &_container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    
  }
  &_instruction-wrapper {
    margin: 2rem;
    background: #fff;
    padding: 1.5rem;
    -webkit-box-shadow: 10px 10px 22px -18px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 22px -18px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 22px -18px rgba(0,0,0,0.75);
    border-radius: 10px
  }
  &_submit {
    margin: 0 auto;
    background: none;
    border: 1px solid lightgray;
    background: #fff;
    outline: none;
    -webkit-appearance: none;
    border-radius: 5px;
    padding: 0.25rem 3rem;
    transition: background 0.2s, color 0.2s;
    &:hover {
      background: #8cc540;
      color: #fff;
      cursor: pointer;
    }
  }
}

.assessment-question {
  &_container {
    margin: 3rem auto;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 40rem;
    height: 23rem;
    border-radius: 10px;
    border-top: 5px solid #8cc540;
    padding: .75rem 1.75rem 1.75rem 1.75rem;
    background: #fff;
    -webkit-box-shadow: 10px 10px 22px -18px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 22px -18px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 22px -18px rgba(0,0,0,0.75);
  }
  &_radio-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 8.25rem
  }
  &_input {
    border: 1px solid rgba(128, 128, 128, 0.329);
    border-radius: 5px;
    margin-bottom: 0;
    background-color: transparent;
    font-size: 12px;
    text-align: center;
    &:active,
    &:focus {
      outline: none;
    }
  }
  &_back-to-assessment-button {
    border-radius: 10px;
    padding: .5rem;
    max-width: 15rem;
    margin: 0 auto;
    -webkit-appearance: none;
    background: #8cc540;
    color: #fff;
    border: none;
    transition: background 0.2s, color 0.2s;
    &:hover {
      background: #8cc540;
      color: #fff;
      cursor: pointer;
    }
    &:active,
    &:focus {
      outline: none;
    }
  }
  &_radio-label {
    margin-left: 0.5rem;
  }
  &_radio-input {
    &:hover {
      cursor: pointer;
    }
  }
  &_skip-button-container {
    text-align: right;
  }
  &_skip-button {
    border-radius: 8px;
    padding: .25rem;
    width: 5rem;
    -webkit-appearance: none;
    background: none;
    color: #8cc540;
    font-weight: 500;
    border: 1px solid #8cc540;
    transition: background 0.2s, color 0.2s;
    &:hover {
      background: #8cc540;
      color: #fff;
      cursor: pointer;
    }
    &:active,
    &:focus {
      outline: none;
    }
  }
  &_next-button-container {
    width: 100%;
    text-align: right;
  }
  &_next-button {
    border-radius: 8px;
    padding: .25rem;
    width: 5rem;
    -webkit-appearance: none;
    background: none;
    color: #8cc540;
    font-weight: 500;
    border: 1px solid #8cc540;
    transition: background 0.2s, color 0.2s;
    &:hover {
      background: #8cc540;
      color: #fff;
      cursor: pointer;
    }
    &:active,
    &:focus {
      outline: none;
    }
  }
  &_title {
    margin-bottom: 2rem;
    font-size: 1rem;
    font-weight: bold;
  }
  &_input-text-area {
    border-radius: 5px;
    border: 1px solid lightgray;
    max-width: 100%;
    padding: .75rem;
    font-size: 12px;
    &:active,
    &:focus {
      outline: none;
    }
  }
  &_content-container {
    margin: 2rem 0;
  }
}

.assessment-modal {
  &_wrapper {
    padding: 1.5rem;
    text-align: center;
    min-width: 26rem;
    min-height: 14rem;
    
  }
  &_title {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  &_option {
    width: 21rem;
    margin: .75rem auto;
    border-radius: 10px;
    padding: 0.5rem 0;
    transition: background 0.1s, color 0.1s;
    &:hover {
      cursor: pointer;
      background: #8cc540;
      color: #fff;
    }
  }
}

.assessment-loader {
  &_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.homepage-loader {
  &_container {
    margin: 0 auto;
    max-width: 5rem;
  }
}
