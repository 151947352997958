.subject-view {
  &_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #f3f3f3c0;
    height: 100vh;
    overflow: auto;
  }
  &_button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_table-title {
    font-weight: 100;
    font-size: 1.5rem;
    text-align: center;
  }
  &_patient-info {
    display: flex;
    margin-top: 1rem;
    p {
      margin-left: 0.25rem;
      font-size: 24px;
    }
  }
  &_back-button {
    margin-top: .5rem;
    background: none;
    border: 1px solid lightgray;
    background: #fff;
    border-radius: 10px;
    padding: 0.25rem 1.5rem;
    transition: background 0.2s, color 0.2s;
    &:hover {
      background: #8dc440;
      color: #fff;
    }
    &:active,
    &:focus {
      outline: none;
    }
  }
  &_content-container {
    margin: 0 2rem;
  }
  &_table-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    th {
      background: #fff;
    }
  }
  &_patient-info-container {
    min-width: 50rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  &_data-table-row {
    transition: background 0.2s;
    background: #fff;
    &:hover {
      cursor: pointer;
      background: rgba(255, 255, 255, 0.658);
    }
  }
  &_add-assessment-button {
    background: none;
    border: 1px solid lightgray;
    background: #fff;
    border-radius: 10px;
    padding: 0.25rem 1.5rem;
    transition: background 0.2s, color 0.2s;
    &:hover {
      background: #8dc440;
      color: #fff;
    }
    &:active,
    &:focus {
      outline: none;
    }
  }
  &_loader-container {
    text-align: center;
    margin: 0 auto;
  }
}
