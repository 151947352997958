.login {
  &_tos-container {
    display: flex;
    align-items: center;
  }
  &_logo {
    max-width: 22rem;
  }
  &_input-label {
    color: #8DC440 !important;
  }
  &_tos-container {
    display: flex;
    justify-content: center;
  }
  &_tos-text {
    color: #333;
    margin: 5px 0 5px 5px;
  }
  &_tos-text-link {
    color: #0000EE;
    &:hover {
      cursor: pointer;
    }
  }
  &_tos-checkbox {
    position: relative;
  }
  &_patient-message {
    font-size: 12px;
    margin: 0;
  }
  &_sign-up-message {
    text-align: center;
    font-size: 12px;
  }
}

.tos {
  &_hippa-logo {
    max-width: 19rem;
  }
  &_text { 
    color: #fff;
  }
}
