.homepage-header-container {
  padding: .25rem 1.25rem;;
  width: 100%;
  background: #F8F8F8;
  display: flex;
  align-items: center;
  border-bottom: 1px solid;
  border-image-source: linear-gradient(45deg, #8CC540, #8CC540);
  border-image-slice: 1;
  h1 {
    margin-top: 0;
  }
}

.homepage-header {
  &_content-container {
    display: flex;
  }
  &_text-container {
    margin-left: 2.25rem;
  }
  &_logo-container {
    max-width: 4rem;
  }
  &_logo {
    width: 100%;
  }
  &_nav-menu {
    display: flex;
  }
  &_nav-link {
    margin: 0 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    color: rgb(87, 87, 87);
    margin-bottom: 0;
    &:hover {
      cursor: pointer;
    }
  }
  &_nav-user-container {
    display: flex;
    position: absolute;
    right: 1.5rem;
  }
  &_logout {
    margin-bottom: 0;
    font-weight: bold;
    text-transform: uppercase;
    color: rgb(87, 87, 87);
    margin-bottom: 0;
    &:hover {
      cursor: pointer;
    }
  }
  &_nav-user {
    position: relative;
    top: 0;
    color: rgba(87, 87, 87, 0.432);
    right: 1.5rem;
    font-size: 1.5rem;
  }
}
.homepage-header-content {
  line-height: 26px;
}