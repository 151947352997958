.profile {
  &_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #f3f3f3c0;
    height: 100vh;
  }
  &_input-wrapper {
    text-align: center;
    p {
      margin-bottom: 0;
    }
  }
  &_input-text {
    border-radius: 8px;
    border: 1px solid lightgray;
    text-align: center;
    padding: 0.5rem 1rem;
    width: 19rem;
    &:active,
    &:focus {
      outline: none;
    }
    &--phone {
      width: 14.75rem;
      margin-right: .25rem;
    }
  }
  &_title {
    font-weight: 100;
    text-align: center;
  }
  &_inputs-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 60rem;
    align-items: flex-end;
  }
  &_names-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }
  &_input-select {
    height: 2.6rem;
    background: #fff;
    border: 1px solid lightgray;
  }
  &_submit-button {
    -webkit-appearance: none;
    border: 1px solid lightgray;
    margin-bottom: 1rem;
    padding: 0.5rem 3rem;
    border-radius: 10px;
    background: #8cc540;
    margin-top: 0.5rem;
    color: #fff;
    position: relative;
    top: 1px;
    &:hover {
      cursor: pointer;
    }
    &:active,
    &:focus {
      outline: none;
    }
  }
  &_content {
    display: flex;
  }
  &_content-wrapper {
    margin-top: 2rem;
    background: #fff;
    -webkit-box-shadow: 10px 10px 35px -20px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 35px -20px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 35px -20px rgba(0, 0, 0, 0.75);
    border-radius: 8px;
    padding: .5rem 2rem 2rem 2rem;
  }
  &_default-icon {
    font-size: 8rem;
    color: lightgray;
    text-align: center;
  }
  &_content-meta {
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
  }
  &_content-meta-about {
    border-radius: 8px;
    border: 1px solid lightgray;
    font-size: 12px;
    padding: 1rem;
    margin-top: .25rem;
    &:active,
    &:focus {
      outline: none;
    }
  }
  &_phone-prefix-container {
    display: flex;
  }
  &_required-asterisk {
    font-size: 1.5rem;
    color: red;
    margin-right: 2px;
    position: relative;
    top: 6px;
  }
  &_about-label {
    margin-bottom: 0;
    text-align: center;
  }
  &_home-button {
    font-size: 1.5rem;
    position: absolute;
    &:hover {
      cursor: pointer;
    }
    &:active,
    &:focus {
      outline: none;
    }
  }
}
